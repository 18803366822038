<template>
  <div class="yujing">
    <div class="title">主机预感</div>
    <div class="yujing-body">
      <div class="item">
        <div class="item-t">总数</div>
        <div class="item-b">25</div>
      </div>
      <div class="item">
        <div class="item-t">正常</div>
        <div class="item-b">21</div>
      </div>
      <div class="item">
        <div class="item-t">故障</div>
        <div class="item-b">0</div>
      </div>
      <div class="item">
        <div class="item-t">报警</div>
        <div class="item-b">4</div>
      </div>
      <div class="item">
        <div class="item-t">离线</div>
        <div class="item-b">0</div>
      </div>
      <div class="item">
        <div class="item-t">屏蔽</div>
        <div class="item-b">0</div>
      </div>
    </div>
    <div class="title">烟感</div>
    <div class="yujing-body">
      <div class="item">
        <div class="item-t">总数</div>
        <div class="item-b">1</div>
      </div>
      <div class="item">
        <div class="item-t">正常</div>
        <div class="item-b">1</div>
      </div>
      <div class="item">
        <div class="item-t">报警</div>
        <div class="item-b">0</div>
      </div>
    </div>
  </div>
</template>

<script>
import { wechatInteractiveRecordUrl } from "./api";
export default {
  name: "yujing",
  data() {
    return {
      oData: [],
      axiosHeader: {
        "Cockpit-Auth":
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjkyMjMzNzIwMzY4NTQ3NzU4MDcsInBob25lIjoiMTU5MTIzNDU2NzgiLCJ1c2VybmFtZSI6ImFkbWluX3lkIn0.K5cNEP6h76OBqHYSyYqHviXPJrPSNkbs2cxEgG-x0tA",
      },
    };
  },
  mounted() {
    this.getRecord();
  },
  methods: {
    async getRecord() {
      let res = await this.$axios.get(`${wechatInteractiveRecordUrl}`, {
        headers: this.axiosHeader,
      });
      if (res.code == 0) {
        this.oData = res.data;
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.yujing {
  .title {
    text-align: center;
    font-size: 30px;
    line-height: 80px;
  }
  .yujing-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
  }
  .item {
    width: 200px;
    height: 200px;
    text-align: center;
    background: url("./img/blueyujing.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 20px;
    padding-top: 50px;
    box-sizing: border-box;
    .item-t {
    }
    &:nth-child(2) {
      background: url("./img/lvyujing.png") no-repeat;
      background-size: 100% 100%;
    }
    &:nth-child(3) {
      background: url("./img/hongyujing.png") no-repeat;
      background-size: 100% 100%;
    }
    &:nth-child(7) {
      background: url("./img/hongyujing.png") no-repeat;
      background-size: 100% 100%;
    }
    &:nth-child(8) {
      background: url("./img/lvyujing.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
